import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../_services/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiMethodsServices } from '../../../_services/api-methods.service';
import { TranslateService } from '@ngx-translate/core';




@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, AfterViewInit {

    signInForm: FormGroup;
    forgetPasswordForm: FormGroup;

    loginPhone: string = '';
    loginPassword: string = '';

    fp_email: string = '';

    rpassword: string = '';
    current_user: {};

    success: boolean = true;
    misMatchPass: boolean = false;
    existed_email: boolean = false;

    loader: boolean = false;
    public emailErrorMsg: string
    public passErrorMsg: string
    public invalidLoginErrorMsg: string


    returnUrl: string;

    fp_msg_status = false;
    fp_msg_type = '';
    fp_msg_content = '';
    login_as: any;
    sub: any;

    constructor(private _script: ScriptLoaderService, public commonService: CommonService, public http: HttpClient, private fb: FormBuilder, private router: Router, private actRouter: ActivatedRoute
        , public apiMethodsServices: ApiMethodsServices
        , public translateService: TranslateService


    ) {

        this.signInForm = fb.group({
            'loginPhone': [null, Validators.required],
            'loginPassword': [null, Validators.required],
        });

        // this.forgetPasswordForm = fb.group({
        //     'fp_email': [null, Validators.required],
        // });


        this.emailErrorMsg = this.translateService.instant('L_isReq');
        this.passErrorMsg = this.translateService.instant('L_isReq');


    }

    login(login,isPartner:boolean=false) {
        this.invalidLoginErrorMsg = ''
        let body: any = {
            'phone': login.loginPhone,
            'password': login.loginPassword,
            'language': this.commonService.currnetLanguage.code
        }

        if (login.email !== '' || login.email !== null) {
            this.loader = true
            let url = this.getApiUrl();
            if(!!url){
                this.apiMethodsServices.post(url, body).subscribe((data) => {
                    this.loader = false
                    this.loginPhone = '';
                    this.loginPassword = '';
                    this.success = false;
                    this.misMatchPass = false;
                    this.commonService.setStorageUserData(data)
                    this.router.navigateByUrl('orders/all-orders');
                }, err => {
    
                    this.loader = false;
                    if (err.status == 0) {
                        this.commonService.showTost(this.translateService.instant('L_errorConnection'), 'error', true);
                    }
                    else {
                        if (err.error) {
                            if (err.error.message) {
                                this.invalidLoginErrorMsg = err.error.message
                            } else {
                                this.commonService.showTost(this.translateService.instant('L_someErrorHappen'), 'error', true);
    
                            }
                        }
    
                    }
                });
            }
           

        }

    }

    getApiUrl(){
        let url='';
        switch(this.login_as){
            case 'admin': url='login';
            break;
            case 'owner': url='login/as/owner';
            break;
            case 'partner': url='login/as/partner';
            break;
        }
        return url;

    }
    
   
    // forgetPassword(fp) {

    //     let body: any = {
    //         'email': fp.fp_email,
    //     }


    //     if (fp.fp_email !== '' || fp.fp_email !== null) {

    //         this.apiMethodsServices.post('Account/ForgotPassword', body).subscribe((data) => {
    //             if (data) {
    //                 if (data.success) {

    //                     this.fp_msg_status = true;
    //                     this.fp_msg_type = 'success';
    //                     this.fp_msg_content = 'تم ارسال رسالة الى البريد الالكتروني يرجى مراجعة البريد الخاص بك لاتمام العملية';


    //                 }
    //                 else if (data.error) {
    //                     this.fp_msg_status = true;
    //                     this.fp_msg_type = 'danger';
    //                     this.fp_msg_content = data.error;
    //                 }

    //             }

    //         });

    //     }

    // }
    // backToLogin() {
    //     this.fp_msg_status = false;
    //     this.fp_msg_type = '';
    //     this.fp_msg_content = '';
    //     this.fp_email = ''
    // }


    ngOnInit() {

        this.sub = this.actRouter.data
            .subscribe(params => {
                this.login_as = params.login_as ? params.login_as : '';
                console.log("🚀 ~ file: login.component.ts:161 ~ LoginComponent ~ ngOnInit ~ this.login_as:", this.login_as)
            }
            );



        this.actRouter.queryParams.subscribe((params: any) => {
            if (params.login_as) {
                this.login_as = params.login_as ? params.login_as : '';
            }
        })

        this.translateService.onLangChange.subscribe(() => {
            this.emailErrorMsg = this.translateService.instant('L_isReq');
            this.passErrorMsg = this.translateService.instant('L_isReq');
          });
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    ngAfterViewInit() {
        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/default/base/scripts.bundle.js', 'assets/demo/default/base/myJs.js'], true).then(() => {
                Helpers.setLoading(false);
            });

        this._script.loadScripts('app-login',
            ['assets/snippets/pages/user/login.js']);

        Helpers.bodyClass('m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default');


    }

}