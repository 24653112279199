import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { LoginModule } from './pages/login/login.module';
import { AuthGuard } from "../auth.guard";
import { UnAuthGuard } from "../unAuth.guard";

import { LoginComponent } from './pages/login/login.component';
import { IndexComponent } from './pages/web-pages/index/index.component';




const routes: Routes = [
    {
        "path": "",
        "component": ThemeComponent,
        //"canActivate": [AuthGuard],
        "children": [
            //settings
            {
                "path": "settings",
                "loadChildren": ".\/pages\/settings\/all-settings\/all-settings.module#AllSettingsModule"
            },
            //drivers
            {
                "path": "drivers\/all-drivers",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/drivers\/all-drivers\/all-drivers.module#AllDriversModule"
            },
            {
                "path": "drivers\/driver",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/drivers\/add-driver\/add-driver.module#AddDriverModule"
            },
            {
                "path": "drivers\/driver\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/drivers\/add-driver\/add-driver.module#AddDriverModule"
            },
            {
                "path": "drivers\/all-driver_classes",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/drivers\/all-driver_classes\/all-driver_classes.module#AllDriver_classesModule"
            },
            {
                "path": "drivers\/driver_class",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/drivers\/add-driver_class\/add-driver_class.module#AddDriver_classModule"
            },
            {
                "path": "drivers\/driver_class\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/drivers\/add-driver_class\/add-driver_class.module#AddDriver_classModule"
            },


            //stores
            {
                "path": "stores\/all-stores",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/stores\/all-stores\/all-stores.module#AllStoresModule"
            },
            {
                "path": "stores\/store",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/stores\/add-store\/add-store.module#AddStoreModule"
            },
            {
                "path": "stores\/store\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/stores\/add-store\/add-store.module#AddStoreModule"
            },

            {
                "path": "stores\/show-store\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/stores\/show-store\/show-store.module#ShowStoreModule"
            },

            //partners
            {
                "path": "partners\/all-partners",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/partners\/all-partners\/all-partners.module#AllPartnersModule"
            },
            {
                "path": "partners\/partner",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/partners\/add-partner\/add-partner.module#AddPartnerModule"
            },
            {
                "path": "partners\/partner\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/partners\/add-partner\/add-partner.module#AddPartnerModule"
            },

            {
                "path": "partners\/show-partner\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/partners\/show-partner\/show-partner.module#ShowPartnerModule"
            },



            //admins
            {
                "path": "admins\/all-admins",
                "data": { allowedRoles: ['show_sub_admins'] },
                "canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/admins\/all-admins\/all-admins.module#AllAdminsModule"
            },
            {
                "path": "admins\/admin",
                "data": { allowedRoles: ['show_sub_admins'] },
                "canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/admins\/add-admin\/add-admin.module#AddAdminModule"
            },
            {
                "path": "admins\/admin\/:slug",
                "data": { allowedRoles: ['show_sub_admins']},
                "canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/admins\/add-admin\/add-admin.module#AddAdminModule"
            },

            {
                "path": "admins\/show-admin\/:slug",
                "data": { allowedRoles: ['show_sub_admins'] },
                "canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/admins\/show-admin\/show-admin.module#ShowAdminModule"
            },


            //orders
            {
                "path": "orders\/all-orders",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/orders\/all-orders\/all-orders.module#AllOrdersModule"
            },
            {
                "path": "orders\/show-order\/:id",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/orders\/show-order\/show-order.module#ShowOrderModule"
            },

            {
                "path": "orders\/add-order\/:store_slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/orders\/add-order\/add-order.module#AddOrderModule"
            },

            {
                "path": "orders\/edit-order\/:id",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/orders\/add-order\/add-order.module#AddOrderModule"
            },

            //towns
            {
                "path": "towns\/all-towns",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/towns\/all-towns\/all-towns.module#AllTownsModule"
            },
            {
                "path": "towns\/town",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/towns\/add-town\/add-town.module#AddTownModule"
            },
            {
                "path": "towns\/town\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/towns\/add-town\/add-town.module#AddTownModule"
            },

            //packages
            {
                "path": "packages\/all-packages",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/packages\/all-packages\/all-packages.module#AllPackagesModule"
            },
            {
                "path": "packages\/package",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/packages\/add-package\/add-package.module#AddPackageModule"
            },
            {
                "path": "packages\/package\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/packages\/add-package\/add-package.module#AddPackageModule"
            },

            {
                "path": "packages\/all-codes",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/packages\/all-codes\/all-codes.module#AllCodesModule"
            },

            //pages
            {
                "path": "pages\/all-pages",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/pages\/all-pages\/all-pages.module#AllPagesModule"
            },
            {
                "path": "pages\/page",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/pages\/add-page\/add-page.module#AddPageModule"
            },
            {
                "path": "pages\/page\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/pages\/add-page\/add-page.module#AddPageModule"
            },

            {
                "path": "categories\/all-categories",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/categories\/all-categories\/all-categories.module#AllCategoriesModule"
            },
            {
                "path": "categories\/add-category\/:store_slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/categories\/add-category\/add-category.module#AddCategoryModule"
            },
            {
                "path": "categories\/edit-category\/:slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/categories\/add-category\/add-category.module#AddCategoryModule"
            },

            {
                "path": "products\/all-products",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/products\/all-products\/all-products.module#AllProductsModule"
            },
            {
                "path": "products\/add-product\/:category_id",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/products\/add-product\/add-product.module#AddProductModule"
            },
            {
                "path": "products\/edit-product\/:slug\/:store_slug",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/products\/add-product\/add-product.module#AddProductModule"
            },




            {
                "path": "reports\/store-reports",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/reports\/store-reports\/store-reports.module#StoreReportsModule"
            },


            {
                "path": "reports\/driver-reports",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/reports\/driver-reports\/driver-reports.module#DriverReportsModule"
            },


            {
                "path": "reports\/all-drivers-reports",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/reports\/all-drivers-reports\/all-drivers-reports.module#AllDriversReportsModule"
            },

            {
                "path": "reports\/all-stores-reports",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/reports\/all-stores-reports\/all-stores-reports.module#AllStoresReportsModule"
            },
            {
                "path": "reports\/general-reports",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/reports\/general-reports\/general-reports.module#GeneralReportsModule"
            },
            {
                "path": "reports\/charge-reports",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/reports\/charge-reports\/charge-reports.module#ChargeReportsModule"
            },
            {
                "path": "reports\/daily-order-store-reports",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/reports\/daily-order-store-reports\/daily-order-store-reports.module#DailyOrderStoreReportsModule"
            },
            {
                "path": "reports\/daily-general-reports",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/reports\/daily-general-reports\/daily-general-reports.module#DailyGeneralReportsModule"
            },


            {
                "path": "balances\/all-balances",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/balances\/all-balances\/all-balances.module#AllBalancesModule"
            },

            {
                "path": "tables\/all-tables",
                "data": { allowedRoles: ['admin'] },
                //"canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/tables\/all-tables\/all-tables.module#AllTablesModule"
            },
            // // End My Own Pathes
            // {
            //     "path": "404",
            //     "loadChildren": ".\/pages\/not-found\/not-found.module#NotFoundModule"
            // },
        ]
    },

    // {
    //     "path": "",
    //     "canActivate": [UnAuthGuard],
    //     "component": IndexComponent,
    // },
    // {
    //     "path": "index",
    //     "canActivate": [UnAuthGuard],
    //     "component": IndexComponent,
    // },
    {
        "path": "",
        "canActivate": [UnAuthGuard],
        //"canActivate": [AuthGuard],
        "loadChildren": ".\/pages\/web-pages\/web-pages.module#WebPagesModule"
    },
    {
        "path": "login",
        "canActivate": [UnAuthGuard],
        "component": LoginComponent,
    },




    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule, LoginModule]
})
export class ThemeRoutingModule {

}