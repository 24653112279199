import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// declare var cordova: any;

@Injectable()
export class EventPublishService {
    //server
    private fooSubject = new Subject<any>();
    constructor(
    ) {
    }

    ///////////////////////// ****************************************** ///////////////////////////
    /////////////////////////                   Start  API                       //////////////////// 
    ///////////////////////// ****************************************** ///////////////////////////   

    publishSomeData(data: any) {
        this.fooSubject.next(data);
    }

    getObservable(): Subject<any> {
        return this.fooSubject;
    }

}


