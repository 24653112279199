import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '../../helpers';
import { ScriptLoaderService } from '../../_services/script-loader.service';
import { Router } from '@angular/router';
import { CommonService } from '../../_services/common.service';

@Component({
    selector: ".m-grid__item.m-grid__item--fluid.m-grid.m-grid--ver-desktop.m-grid--desktop.m-body",
    templateUrl: "./default.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class DefaultComponent implements OnInit {


    constructor(private router: Router , public commonService:CommonService) {

    }
    ngOnInit() {

        // if (localStorage.getItem('userInfo') === null || localStorage.getItem('token') === null   ) {
        //     this.router.navigate(['/login']);
        // }

    }

}