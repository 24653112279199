import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { CommonService } from './_services/common.service';

@Injectable()
export class UnAuthGuard implements CanActivate {

    constructor(public commonService: CommonService,
        private _router: Router,
    ) {
    }
    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let token = await this.commonService.localStorage().get('userToken')
        if (token) {
            this._router.navigate(['/orders/all-orders']);
            return false
        } else {
            return true
        }
    }
}
