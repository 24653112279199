import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { CommonService } from './_services/common.service';
import { findIndex } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, public commonService: CommonService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (this.commonService.userToken) {
            let allowedRoles = next.data.allowedRoles;
            if (allowedRoles) {
                let auth = false

                console.log("🚀 ~ file: auth.guard.ts:20 ~ AuthGuard ~ canActivate ~  this.commonService.IsSuperAdmin:",  this.commonService.IsSuperAdmin)


                for (let index = 0; index < allowedRoles.length; index++) {
                    if (this.commonService.userInfo[allowedRoles[index]] == 1 || this.commonService.IsSuperAdmin) {
                        auth = true;
                        break;
                    }
                }
                if (auth == true) {
                    return true;
                } else {
                    this._router.navigate(['/index']);
                    return false;

                }
            }

            else {
                return true;
            }


        } else {
            this._router.navigate(['/index']);
            return false;

        }
    }
}
