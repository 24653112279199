import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CommonService } from './common.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { UsersType } from '../_utilities/enums';
// declare var cordova: any;

@Injectable()
export class ApiMethodsServices {
    //   public API_URL = 'https://cotobuild.azurewebsites.net/api/';
    //  public API_URL = 'https://a9c2f9ae5b33.ngrok.io/api/';

    public API_URL: string
    public UPLOAD_URL: string
    public env = environment;
    public menuUrl: string;
    constructor(
        public http: HttpClient,
        public commonService: CommonService,

    ) {
        this.API_URL = this.env.domain + 'api/'
        this.UPLOAD_URL = this.env.domain + 'storage/'


        this.menuUrl = this.env.menuUrl
    }

    get(endpoint: string, params?: any) {


        let httpParams: HttpParams = new HttpParams({});
        if (params) {
            for (let param in params) {
                httpParams = httpParams.set(param, params[param])
            }
        }
        let httpHeaders: HttpHeaders = new HttpHeaders({})
        // httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', '*');
        httpHeaders = httpHeaders.set('Accept-language', this.commonService.currnetLanguage.code);

        httpHeaders = httpHeaders.set('Authorization', this.commonService.userToken);

        return this.http.get<any>(this.API_URL + endpoint, { params: httpParams, headers: httpHeaders })
    }

    post(endpoint: string, body: any, params?: any, headers?: any) {

        let httpParams: HttpParams = new HttpParams({});
        let httpHeaders: HttpHeaders = new HttpHeaders({})
        if (httpParams && params) {

            for (let param in params) {
                httpParams = httpParams.set(param, params[param])

            }
        }
        // httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', '*');
        httpHeaders = httpHeaders.set('Accept-language', this.commonService.currnetLanguage.code);

        httpHeaders = httpHeaders.set('Authorization', this.commonService.userToken);

        // if (this.localStorage().get('authentication_code')) {// authentication_code for login user
        //     // //console.log('authentication_code',this.localStorage().get('authentication_code'))
        //     httpHeaders = httpHeaders.set('userToken', this.localStorage().get('authentication_code'))


        // }

        if (httpHeaders && headers) {
            for (let header in headers) {
                httpHeaders = httpHeaders.set(header, headers[header])
            }
        }
        // //console.log('httpHeaders',httpHeaders)
        // // //console.log(httpHeaders.get('token'))
        // //console.log('headers',headers)
        // //console.log('body',body)


        return this.http.post<any>(this.API_URL + endpoint, body, { params: httpParams, headers: httpHeaders })
    }



    delete(endpoint: string, params?: any) {


        let httpParams: HttpParams = new HttpParams({});
        if (params) {
            for (let param in params) {
                httpParams = httpParams.set(param, params[param])
            }
        }
        let httpHeaders: HttpHeaders = new HttpHeaders({})
        // httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', '*');
        httpHeaders = httpHeaders.set('Accept-language', this.commonService.currnetLanguage.code);

        httpHeaders = httpHeaders.set('Authorization', this.commonService.userToken);

        return this.http.delete<any>(this.API_URL + endpoint, { params: httpParams, headers: httpHeaders })
    }






    put(endpoint: string, body: any, params?: any, headers?: any) {

        let httpParams: HttpParams = new HttpParams({});
        let httpHeaders: HttpHeaders = new HttpHeaders({})
        if (httpParams && params) {

            for (let param in params) {
                httpParams = httpParams.set(param, params[param])

            }
        }
        // httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', '*');
        httpHeaders = httpHeaders.set('Accept-language', this.commonService.currnetLanguage.code);

        httpHeaders = httpHeaders.set('Authorization', this.commonService.userToken);

        // if (this.localStorage().get('authentication_code')) {// authentication_code for login user
        //     // //console.log('authentication_code',this.localStorage().get('authentication_code'))
        //     httpHeaders = httpHeaders.set('userToken', this.localStorage().get('authentication_code'))


        // }

        if (httpHeaders && headers) {
            for (let header in headers) {
                httpHeaders = httpHeaders.set(header, headers[header])
            }
        }
        // //console.log('httpHeaders',httpHeaders)
        // // //console.log(httpHeaders.get('token'))
        // //console.log('headers',headers)
        // //console.log('body',body)


        return this.http.put<any>(this.API_URL + endpoint, body, { params: httpParams, headers: httpHeaders })
    }

    getAccountData() {
        // console.log(this.commonService.userInfo.id)
        if (this.commonService.userInfo.id && (this.commonService.IsStore ||this.commonService.IsPartner)) {
            // let params = { full: '' }
            this.get('my-info', {})
                .subscribe((ret: any) => {
                    let data = { user: ret.info }
                    this.commonService.setStorageUserData(data)
                }, err => {

                })
        }

    }
    public downloadFile(endpoint, file): Observable<any> {
        // Create url
        let httpHeaders: HttpHeaders = new HttpHeaders({})
        httpHeaders = httpHeaders.set('Authorization', this.commonService.userToken);
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');


        let url = this.API_URL + endpoint;
        var body = { filename: file };
        return this.http.post(url, body, {
            responseType: "blob",
            headers: httpHeaders
        });
    }

}


