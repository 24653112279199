import { Component, OnInit, ViewEncapsulation, AfterViewInit, NgZone } from '@angular/core';
import { Helpers } from '../../../helpers';
import { Router } from '@angular/router';
import { CommonService } from '../../../_services/common.service';
import { ApiMethodsServices } from '../../../_services/api-methods.service';
import { TranslateService } from '@ngx-translate/core';
// import { SettingsService } from '../app/theme/pages/main-components/settings/settings.service';
declare var jquery: any;
declare var $: any;
declare let mLayout: any;
@Component({
  selector: "app-header-nav",
  templateUrl: "./header-nav.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {

  user_name: string = '';
  email: string = '';

  site_name: string = '';


  loader: boolean = false;

  showUpdate: boolean = true
  showUpdateMessage: any;


  change_password = {
    old_password: "",
    password: "",
    password_confirmation: "",
  }


  errorBoolean = {
    old_password: "",
    password: "",
    password_confirmation: "",
  }

  changePasswordLoader: boolean = false
  constructor(private router: Router, public commonService: CommonService
    , public apiMethodsServices: ApiMethodsServices,
    public ngZone: NgZone,
    public translateService: TranslateService,


  ) {

  }
  ngOnInit() {
  }



  logout() {
    this.loader = true
    //console.log('ssssssssssssssssssssssssssssssssssss')
    this.apiMethodsServices.post('logout', {}).subscribe((data) => {
      this.loader = false
      this.ngZone.run(() => {
        this.commonService.removeUserData()
        this.router.navigateByUrl('/');

      })



    }, err => {
      if (err.status == 0) {
        this.commonService.showTost(this.translateService.instant('L_errorConnection'), 'error', true);
      } else if (err.status == 401) {
        this.commonService.removeUserData()
        this.router.navigateByUrl('/');
      } else if (err.status == 404) {
        this.commonService.showTost(err.error.message, 'error', true);
        // this.navController.back();
      }
      else {
        if (err.error) {
          if (err.error.message) {
            this.commonService.showTost(err.error.message, 'error', true);
          } else {
            this.commonService.showTost(this.translateService.instant('L_someErrorHappen'), 'error', true);

          }
        }

      }

      this.loader = false
      this.ngZone.run(() => {
        this.commonService.removeUserData()
        this.router.navigateByUrl('/');
      })



    });

  }
  changePassword() {

    this.errorBoolean = {
      old_password: "",
      password: "",
      password_confirmation: "",
    }
    this.changePasswordLoader = true

    this.apiMethodsServices.post('change-password', this.change_password).subscribe((data) => {

      this.change_password = {
        old_password: "",
        password: "",
        password_confirmation: "",
      }
      this.commonService.showTost(this.translateService.instant('L_don_successfully'), 'success', true);
      $("#paidModal").modal('hide');
      this.changePasswordLoader = false;
    }, err => {
      if (err.status == 0) {
        this.commonService.showTost(this.translateService.instant('L_errorConnection'), 'error', true);
      } else if (err.status == 401) {
        this.commonService.removeUserData()
        this.router.navigateByUrl('/');
      } else if (err.status == 404) {
        this.commonService.showTost(err.error.message, 'error', true);
        // this.navController.back();
      }
      else {
        if (err.error) {
          if (err.error.message) {
            this.commonService.showTost(err.error.message, 'error', true);
          } else {
            this.commonService.showTost(this.translateService.instant('L_someErrorHappen'), 'error', true);

          }
        }

      }

      this.changePasswordLoader = false;
      if (err.error.errors) {
        let keys = Object.keys(err.error.errors);
        if (keys.length > 0) {
          for (let i = 0; i < keys.length; i++) {
            // console.log(keys[i])
            this.errorBoolean[keys[i]] = err.error.errors[keys[i]][0] ? err.error.errors[keys[i]][0] : ''
          }
        }
      }

    })

  }
  ngAfterViewInit() {
    mLayout.initHeader();
  }
}