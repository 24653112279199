import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastrManager } from "ng6-toastr-notifications";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { UsersType } from "../_utilities/enums";
import { UserTypeNames } from "../_utilities/consts";

declare var jquery: any;
declare var $: any;

@Injectable()
export class CommonService {
  public GOOGLE_MAP_KEY = "AIzaSyAuBgi9AeZeDIUjV3fdvC8lASnpmwoxuvY";
  public MAB_BOK_MAP_KEY =
    "pk.eyJ1IjoiYWtpbGxpa3VyeWUiLCJhIjoiY2xqNjh1OThuMDN1ODNlbXVudGM1Z25wNCJ9.YXwoYRj_C9WrkV45y8SYCQ";

  public defaultImg: string;
  public defaultAvatarImg: string;
  public userInfo: any = {};
  public userToken: string = "";
  public currnetLanguage = {
    name_by: "Türkçe olarak",
    code: "tr",
    name: "Türkçe",
    lang_image: "./assets/images/languages/turkey.png",
  };
  public loadingImgPath: string;
  public EMAIL_REGEXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public languages = [
    {
      name_by: "باللغة العربية",
      code: "ar",
      name: "العربية",
      lang_image: "./assets/images/languages/arabic.png",
    },
    {
      name_by: "In English",
      code: "en",
      name: "English",
      lang_image: "./assets/images/languages/english.png",
    },
    {
      name_by: "Türkçe olarak",
      code: "tr",
      name: "Türkçe",
      lang_image: "./assets/images/languages/turkey.png",
    },
  ];
  public anyMessage = {
    permissionMsgType: this.translate.instant("L_warnning"),
    permissionMsg: this.translate.instant("L_dontHavePermission"),
  };

  selectedTab: any = 0;
  items: any[];
  defaultAddFile: string;
  defaultFile: string;
  defaultCoverImage: string;
  userType: UsersType;

  constructor(
    private titleService: Title,
    private http: HttpClient,
    public toastr: ToastrManager,
    public translate: TranslateService,

    private title: Title,
    private meta: Meta
  ) {
    this.defaultImg = "./assets/images/no_image.png";
    this.defaultAvatarImg = "./assets/images/profile/userAvatar.svg";
    this.loadingImgPath = "./assets/images/spinner.gif";
    this.defaultAddFile = "./assets/images/plus.png";
    this.defaultFile = "./assets/images/file.png";
    this.defaultCoverImage = "./assets/images/fb-shop.png";

    this.items = this.items instanceof Array ? this.items : [];
    this.getStorgeData();
  }

  async getStorgeData() {
    this.userInfo = await this.localStorage().getObject("userInfo");
    console.log(
      "🚀 ~ file: common.service.ts:60 ~ CommonService ~ getStorgeData ~ userInfo:",
      this.userInfo
    );

    this.userToken = await this.localStorage().get("userToken");
    // console.log(this.userToken)
    this.userToken ? this.disapleLandPageStyleFile() : "";

    let currnetLanguage = await this.localStorage().getObject(
      "currnetLanguage"
    );
    this.currnetLanguage = currnetLanguage.code
      ? currnetLanguage
      : this.currnetLanguage;

    this.cheakManegement();
    this.setLang();
  }
  cheakManegement() {
    if (this.userInfo.roles) {
      this.userType = this.userInfo.roles[0].id;
    } else {
      this.userType = UsersType.Store;
    }
  }

  setStorageUserData(data) {
    this.userInfo = data.user;
    this.cheakManegement();
    this.localStorage().setObject("userInfo", this.userInfo);
    if (data.access_token) {
      this.userToken = data.token_type + " " + data.access_token;
      this.localStorage().set("userToken", this.userToken);
    }

    this.userToken ? this.disapleLandPageStyleFile() : "";
  }

  removeUserData() {
    this.userInfo = {};
    this.userToken = "";
    this.userType = null;
    this.localStorage().setObject("userInfo", this.userInfo);
    this.localStorage().set("userToken", this.userToken);
    this.enableLandPageStyleFile();
  }
  disapleLandPageStyleFile() {
    var sheets = document.getElementById(
      "bootstrap_landing_page"
    ) as HTMLLinkElement;
    sheets.disabled = true;
  }
  enableLandPageStyleFile() {
    var sheets = document.getElementById(
      "bootstrap_landing_page"
    ) as HTMLLinkElement;
    sheets.disabled = false;
  }
  setLang() {
    this.translate.use(this.currnetLanguage.code);
    this.translate.setDefaultLang(this.currnetLanguage.code);

    var sheets = document.getElementById("lang_style_tag") as HTMLLinkElement;

    // console.log("sheets", sheets)
    if (this.currnetLanguage.code == "ar") {
      sheets.href = "./assets/demo/default/base/style-ar.bundle.css";
    } else {
      sheets.href = "./assets/demo/default/base/style.bundle.css";
    }

    setTimeout(() => {
      let metaData = {
        meta_title: this.translate.instant("L_meta_title"),
        meta_key_word: "توصيل الذكي - Akilli Kurye",
        meta_description: this.translate.instant("L_meta_desc"),
        meta_image: "./assets/images/logo-dash.png",
      };
      this.setAllMeta(metaData);
    }, 2000);
  }
  // roules

  limitString(txt: string, txtLength: number) {
    txt = txt ? txt : "";
    txt = txt.replace(/<\/?[^>]+(>|$)/g, "");
    if (txt.length > txtLength && txtLength > 0) {
      const str = txt.substring(0, txtLength);
      return str + " ...";
    } else {
      return txt;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////

  check_all(event) {
    if (event.target.checked) {
      var ids = [];
      $('input[class="checkbox_item"]:checkbox').each(function () {
        ids.push($(this).val());
        $(this).prop("checked", true);
      });

      this.items = ids;
    } else {
      $('input[class="checkbox_item"]:checkbox').each(function () {
        $(this).prop("checked", false);
      });
      this.items.length = 0;
    }
  }

  toggleCheckbox(event, id) {
    if (event.target.checked) {
      this.items.push(id);
    } else {
      $('input[class="check_all"]:checkbox:checked').prop("checked", false);
      var index = this.items.indexOf(id);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////

  range(start, end, step) {
    var range = [];
    var typeofStart = typeof start;
    var typeofEnd = typeof end;

    if (step === 0) {
      throw TypeError("Step cannot be zero.");
    }

    if (typeofStart == "undefined" || typeofEnd == "undefined") {
      throw TypeError("Must pass start and end arguments.");
    } else if (typeofStart != typeofEnd) {
      throw TypeError("Start and end arguments must be of same type.");
    }

    typeof step == "undefined" && (step = 1);

    if (end < start) {
      step = -step;
    }

    if (typeofStart == "number") {
      while (step > 0 ? end >= start : end <= start) {
        range.push(start);
        start += step;
      }
    } else if (typeofStart == "string") {
      if (start.length != 1 || end.length != 1) {
        throw TypeError("Only strings with one character are supported.");
      }

      start = start.charCodeAt(0);
      end = end.charCodeAt(0);

      while (step > 0 ? end >= start : end <= start) {
        range.push(String.fromCharCode(start));
        start += step;
      }
    } else {
      throw TypeError("Only string and number types are supported");
    }

    return range;
  }
  openNav(link) {
    window.open(link, "_blank");
  }

  openLocation(latitude, longitude) {
    window.open(
      "https://www.google.com/maps/?q=" + latitude + "," + longitude,
      "_blank"
    );
  }
  //////////////////////////////////// ahmad Klsany ////////////////////////////////////////////////////////////
  goToTab(inx) {
    this.selectedTab = inx;
  }
  removeTagHtml(date) {
    return date.replace(/<\/?[^>]+(>|$)/g, "");
  }
  numbFloat(number) {
    return parseFloat(number);
  }

  dateDiffInDays(a, b) {
    let _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  englishNumbersFormat(str) {
    if (str) {
      str = "" + str;
      let persianNumbers = [
        /۰/g,
        /۱/g,
        /۲/g,
        /۳/g,
        /۴/g,
        /۵/g,
        /۶/g,
        /۷/g,
        /۸/g,
        /۹/g,
      ],
        arabicNumbers = [
          /٠/g,
          /١/g,
          /٢/g,
          /٣/g,
          /٤/g,
          /٥/g,
          /٦/g,
          /٧/g,
          /٨/g,
          /٩/g,
        ];
      for (var i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
      return str;
    } else {
      return "";
    }
  }

  showTost(str, type = "info", withOption = false) {
    let toastrOptions: any = {
      showCloseButton: true,
      position: "top-left",
    };

    toastrOptions = withOption ? toastrOptions : {};

    if (type == "info") {
      this.toastr.infoToastr(
        str,
        this.translate.instant("L_notify"),
        toastrOptions
      );
      // code...
    } else if (type == "warning") {
      this.toastr.warningToastr(
        str,
        this.translate.instant("L_warnning"),
        toastrOptions
      );
    } else if (type == "error") {
      this.toastr.errorToastr(
        str,
        this.translate.instant("L_error"),
        toastrOptions
      );
    } else if (type == "success") {
      this.toastr.successToastr(
        str,
        this.translate.instant("L_don"),
        toastrOptions
      );
    }
  }

  // ?input = amoeba
  //   & location=37.76999% 2C - 122.44696
  //     & radius=500
  //       & strictbounds=true
  //         & types=establishment
  //           & key=YOUR_API_KEY

  //  latitude
  // longitude

  // async autoCompleteSearchGoolgeMap(params): Promise<any> {
  //   let url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json';
  //   params.key = this.GOOGLE_MAP_KEY
  //   return await new Promise(resolve => {
  //     let httpHeaders: HttpHeaders = new HttpHeaders({})
  //     httpHeaders = httpHeaders.set('Content-Type', 'application/json');

  //     this.http.get(url, { params: params, headers: httpHeaders }).subscribe((data: any) => {
  //       console.log("🚀 ~ file: common.service.ts:308 ~ CommonService ~ this.http.get ~ data:", data)
  //       if (data) {
  //         // resolve()
  //       }
  //     });
  //   });
  // }

  // async getLatAndLngByPlusCodes(plusCode): Promise<any> {
  //   let url = 'https://plus.codes/api';

  //   let params = {
  //     address: plusCode,
  //     key: this.GOOGLE_MAP_KEY,
  //   }
  //   return await new Promise(resolve => {
  //     this.http.get(url, { params: params }).subscribe((data: any) => {
  //       console.log("🚀 ~ file: common.service.ts:308 ~ CommonService ~ this.http.get ~ data:", data)
  //       if (data) {
  //         resolve({ geometry: data.plus_code.geometry, address: data.plus_code.locality.local_address })
  //       }

  //     });
  //   });

  // }

  async getPlaceIdDetailsByText(input): Promise<any> {
    let url =
      "https://maps.googleapis.com/maps/api/place/findplacefromtext/json";

    let httpHeaders: HttpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.set("Accept", "*/*");
    // httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', '*');
    // httpHeaders = httpHeaders.set('Access-Control-Allow-Headers', '*');
    let params = {
      input: input,
      inputtype: "textquery",
      key: this.GOOGLE_MAP_KEY,
    };
    return await new Promise((resolve) => {
      this.http.get(url, { params: params, headers: httpHeaders }).subscribe(
        (data: any) => {
          console.log(
            "🚀 ~ file: common.service.ts:308 ~ CommonService ~ this.http.get ~ data:",
            data
          );
          if (data.candidates) {
            resolve(this.getPlaceDetailsByPlaceId(data.candidates[0].place_id));
          }
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  async getPlaceDetailsByPlaceId(place_id): Promise<any> {
    let url = "https://maps.googleapis.com/maps/api/place/details/json";
    let params = {
      place_id: place_id,
      key: this.GOOGLE_MAP_KEY,
    };

    let httpHeaders: HttpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.set("Accept", "*/*");

    return await new Promise((resolve) => {
      this.http.get(url, { params: params, headers: httpHeaders }).subscribe(
        (data: any) => {
          console.log(
            "🚀 ~ file: common.service.ts:308 ~ CommonService ~ this.http.get ~ data:",
            data
          );
          if (data) {
            resolve({
              location: data.result.geometry.location,
              formatted_address: data.result.formatted_address,
            });
          }
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }
  async getDistanceMapBox(
    lat,
    lng,
    lat2,
    lng2,
    type = "driving",
    params: any = {}
  ): Promise<any> {
    console.log(
      "🚀 ~ file: common.service.ts:391 ~ CommonService ~ getDistanceMapBox ~ lat, lng, lat2, lng2:",
      lat,
      lng,
      lat2,
      lng2
    );
    let geocodeUrl = `https://api.mapbox.com/directions/v5/mapbox/${type}/${lng},${lat};${lng2},${lat2}.json`;
    params.access_token = this.MAB_BOK_MAP_KEY;
    params.alternatives = true;
    // params.geometries = 'geojson'
    params.language = "en";
    // params.steps = true

    return await new Promise((resolve) => {
      // let httpHeaders: HttpHeaders = new HttpHeaders({})
      // httpHeaders = httpHeaders.set('Content-Type', 'application/json');
      // httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', '*');
      // httpHeaders = httpHeaders.set('Access-Control-Allow-Headers', '*');
      this.http.get(geocodeUrl, { params: params }).subscribe(
        (data: any) => {
          if (data) {
            resolve(data);
          }
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  async getMapAddress(lat, lon, index, lang): Promise<any> {
    let geocodeUrl =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lon +
      "&language=" +
      lang +
      "&key=" +
      this.GOOGLE_MAP_KEY;
    // //console.log("geocodeUrl",geocodeUrl)
    return await new Promise((resolve) => {
      // let httpHeaders: HttpHeaders = new HttpHeaders({})
      // httpHeaders = httpHeaders.set('Content-Type', 'application/json');
      // httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', '*');
      // httpHeaders = httpHeaders.set('Access-Control-Allow-Headers', '*');
      this.http.get(geocodeUrl).subscribe(
        (data: any) => {
          if (data) {
            // code...
            if (data.results[index]) {
              // code...
              resolve(data.results[index].formatted_address);
            }
          }
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  getLocationString(location) {
    let data = "";
    if (location.country) {
      data += location.country.arabicName;
    }

    if (location.governorate) {
      data += "-" + location.governorate.arabicName;
    }
    if (location.city) {
      data += "-" + location.city.arabicName;
    }
    if (location.neighborhood) {
      data += "-" + location.neighborhood.arabicName;
    }
    return data;
  }

  correctLocationObject(_location) {
    if (!_location) {
      let location = {
        longitude: undefined,
        latitude: undefined,
        governorate: {
          name: "",
          arabicName: "",
          url: "",
        },
        city: {
          name: "",
          arabicName: "",
          url: "",
        },
        neighborhood: {
          name: "",
          arabicName: "",
          url: "",
        },
        country: {
          name: "",
          arabicName: "",
          url: "",
        },
      };
    } else {
      if (!_location.governorate) {
        _location.governorate = {
          name: "",
          arabicName: "",
          url: "",
        };
      }
      if (!_location.city) {
        _location.city = {
          name: "",
          arabicName: "",
          url: "",
        };
      }
      if (!_location.neighborhood) {
        _location.neighborhood = {
          name: "",
          arabicName: "",
          url: "",
        };
      }
      if (!_location.country) {
        _location.country = {
          name: "",
          arabicName: "",
          url: "",
        };
      }
    }
    return _location;
  }

  // new
  chooseLanguage(lang) {
    console.log("lang", lang);
    this.localStorage().setObject("currnetLanguage", lang);
    this.currnetLanguage = lang;
    this.setLang();
  }
  localStorage() {
    return {
      set: function (key, value) {
        window.localStorage[key] = value;
      },
      get: function (key) {
        return window.localStorage[key] || "";
      },
      setObject: function (key, value) {
        window.localStorage[key] = JSON.stringify(value);
      },
      getObject: function (key) {
        return JSON.parse(window.localStorage[key] || "{}");
      },
    };
  }

  dateFormat(plus_day) {
    let date = new Date();
    date.setDate(date.getDate() + plus_day);
    const yyyy = date.getFullYear();
    let mm: any = date.getMonth() + 1; // Months start at 0!
    let dd: any = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    let ret = yyyy + "-" + mm + "-" + dd;
    console.log(ret);
    return ret;
  }

  getDateTimetoSetSqlFormat(date) {
    let da = new Date(date);
    let y: any = da.getFullYear();
    let d: any = da.getDate();
    let m: any = da.getMonth() + 1;
    let h: any = da.getHours();
    let min: any = da.getMinutes();
    let s: any = da.getSeconds();
    if (m < 10) {
      m = "0" + m;
    }
    if (d < 10) {
      d = "0" + d;
    }
    if (h < 10) {
      h = "0" + h;
    }
    if (min < 10) {
      min = "0" + min;
    }
    if (s < 10) {
      s = "0" + s;
    }
    let fullDate = y + "-" + m + "-" + d + " " + h + ":" + min + ":" + s;
    return fullDate;
  }
  isNumber(value: string | number): boolean {
    return value != null && value !== "" && !isNaN(Number(value.toString()));
  }

  // META

  setMetaDataByPageNameNotApi(defult_meta, page_name, data: any = {}) {
    let metaData = {
      meta_title: data.meta_title ? data.meta_title : page_name,
      meta_key_word: data.meta_key_word
        ? data.meta_key_word
        : defult_meta.key_words,
      meta_description: data.meta_description
        ? data.meta_description
        : defult_meta._description,
      // meta_image: data.meta_image ? data.meta_image : "/assets/imgs/logo/logo-" + this.selected_lang.lang + ".png"
    };
    this.setAllMeta(metaData);
  }

  setAllMeta(metaData) {
    this.setTitleMeta(metaData.meta_title);
    this.setDescriptionMeta(metaData.meta_description);
    this.setImageMeta(metaData.meta_image);
    this.setKeywordsMeta(metaData.meta_key_word);
  }
  private setTitleMeta(title: string) {
    title = title ? title.replace(/<\/?[^>]+(>|$)/g, "") : title;
    this.title.setTitle(title);
    this.setOgMeta("title", title);
    this.setMeta("title", title);
    this.setMeta("twitter:title", title);
    // console.log(title);
  }
  private setDescriptionMeta(description) {
    description = description
      ? description.replace(/<\/?[^>]+(>|$)/g, "")
      : description;
    this.setMeta("description", description);
    this.setOgMeta("description", description);
    this.setMeta("twitter:description", description);
  }
  private setKeywordsMeta(key_word: string) {
    key_word = key_word ? key_word.replace(/<\/?[^>]+(>|$)/g, "") : key_word;
    this.setMeta("keywords", key_word);
    this.setOgMeta("keywords", key_word);
  }
  private setImageMeta(image, width = "380", height = "200") {
    this.setOgMeta("image", image);
    this.setMeta("image", image);
    this.setMeta("twitter:image", image);
    this.setOgMeta("image:width", width);
    this.setOgMeta("image:height", height);
    // GET & SET IMAGE TYPE META
    const url1 = image.split("/");
    const lastIndex = url1[url1.length - 1];
    let type1 = lastIndex.split(".")[1];
    type1 = type1 == "jpg" ? "jpeg" : type1;
    this.setOgMeta("image:type", type1);
  }
  public setSiteName() {
    this.setMeta("site_name", this.translate.instant("L_meta_title"));
    this.setOgMeta("site_name", this.translate.instant("L_meta_title"));
  }

  private setMeta(name: string, content: string) {
    // this.meta.removeTag('name=' + name);
    this.meta.updateTag({
      name: name,
      content: content,
    });
  }
  private setOgMeta(property: string, content: string) {
    // this.meta.removeTag('property=' + "og:" + property);
    this.meta.updateTag({
      property: "og:" + property,
      content: content,
    });
  }
  //END META

  // start getters

  // System User Types

  get IsAdmin() {
    return this.IsSuperAdmin || this.IsSubAdmin;
  }
  get IsStore() {
    return this.userType == UsersType.Store;
  }
  get IsPartner() {
    return this.userType == UsersType.Partner;
  }
  get IsSubAdmin() {
    return this.userType == UsersType.SubAdmin;
  }
  get IsSuperAdmin() {
    return this.userType == UsersType.Admin
  }
}
