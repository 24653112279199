import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { CommonService } from '../../../_services/common.service';
import { ApiMethodsServices } from '../../../_services/api-methods.service';

declare let mLayout: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {


    constructor(public commonService: CommonService ,public apiMethodsServices: ApiMethodsServices) {

    }
    ngOnInit() {

        //console.log(this.commonService.userInfo, " this.commonService.userInfo ")
    }
    ngAfterViewInit() {

        mLayout.initAside();

    }

}