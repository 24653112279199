import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "./_services/script-loader.service";
import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { AuthGuard } from './auth.guard';
import { FormsModule, ReactiveFormsModule, NgForm } from '@angular/forms';
import { NgxPrintModule } from 'ngx-print';
// Start Main Components
import { CommonService } from "./_services/common.service";
import { ApiMethodsServices } from './_services/api-methods.service';
import { registerLocaleData } from '@angular/common';
import localear from '@angular/common/locales/ar-EG';
 

registerLocaleData(localear);
// End Main Components

// Start Main Sections

// End Main Sections
import { RouterModule } from '@angular/router';
import { SweetAlertService } from 'angular-sweetalert-service';
import { NgDatepickerModule } from 'ng2-datepicker';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { EventPublishService } from './_services/event-publish.service';


import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { UnAuthGuard } from './unAuth.guard';
// import { TokenInterceptorService } from './_services/token-interceptor.service';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        ThemeComponent,
        AppComponent,

    ],
    imports: [
        // NgMultiSelectDropDownModule.forRoot(),
        RouterModule,
        ToastrModule.forRoot(),
        NgDatepickerModule,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ThemeRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxPrintModule,
        TranslateModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        ApiMethodsServices,
        EventPublishService,
        ScriptLoaderService,
        CommonService,

        SweetAlertService,
        Title,
        AuthGuard,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: TokenInterceptorService,
        //     multi: true
        //   },
        UnAuthGuard,
        
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }